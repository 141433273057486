import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { CalendarActions } from './calendar-state.action';
import { ICalendarState, CALENDAR_STATE_TOKEN, IWorkingHoursEmployee, IEmployeeScheduleRes, IScheduleSlot, ICalendarAppointments } from './calendar-state.interface';
import { Observable, tap } from 'rxjs';
import { CalendarApiService } from '../services/calendar-api.service';
// import { RRule } from '../services/rschedule';
// import pkg from "rrule";
import format from 'date-fns/format';
import { patch, removeItem } from '@ngxs/store/operators';
import { Helpers } from '../../../entities/helpers';

@State<ICalendarState>({
  name: CALENDAR_STATE_TOKEN,
  defaults: {
    scheduleSlots: [],
    appointments: [],
    isLoading: false
  }
})
@Injectable()
export class CalendarState {
  constructor(
    private calendarApiService: CalendarApiService,
    private store: Store,
  ) {}

  @Action(CalendarActions.FetchEmployeeSchedule)
  fetchEmployeeSchedule(
    ctx: StateContext<ICalendarState>,
    action: CalendarActions.FetchEmployeeSchedule
  ): Observable<IEmployeeScheduleRes> {
    return this.calendarApiService.getEmployeeSchedule(action.params)
      .pipe(
        tap((res: IEmployeeScheduleRes) => {
          const transformedEvents: IScheduleSlot[] = res.data.map((slot: IWorkingHoursEmployee) => {
            const start = new Date(`${slot.date}T${slot.start_time}`);
            const end = new Date(`${slot.date}T${slot.end_time}`);
            let startTime = format(start, 'HH:mm');
            let endTime = format(end, 'HH:mm');

            return {
              title: `${startTime}-${endTime}`,
              start,
              end
            };
          });

          ctx.patchState({
            scheduleSlots: transformedEvents,
            isLoading: false
          });
      })
    );
  }

  @Action(CalendarActions.SetEmployeeSchedule)
  setEmployeeSchedule(
    ctx: StateContext<ICalendarState>,
    action: CalendarActions.SetEmployeeSchedule
  ): Observable<any> {
    const { schedule_id, data } = action.params;

    return this.calendarApiService.setEmployeeSchedule(schedule_id, data);
  }

  @Action(CalendarActions.DeleteEmployeeSchedule)
  deleteEmployeeSchedule(
    ctx: StateContext<ICalendarState>,
    action: CalendarActions.DeleteEmployeeSchedule
  ): Observable<any> {
    const { schedule_id, data } = action.params;

    return this.calendarApiService.deleteEmployeeSchedule(schedule_id, data)
      .pipe(tap((res) => {
        const state = ctx.getState();
        const newState = state.scheduleSlots.filter(slot => {
          const date = Helpers.formatDate(slot.start as Date);
          return !data.includes(date);
        });

        ctx.setState(
          patch({
            scheduleSlots: newState,
          })
        );
      }));
  }

  @Action(CalendarActions.FetchCalendarAppointments)
  fetchCalendarAppointments(
    ctx: StateContext<ICalendarState>,
    action: CalendarActions.FetchCalendarAppointments
  ): Observable<ICalendarAppointments[]> {
    return this.calendarApiService.getCalendarAppointments(action.params)
      .pipe(
        tap((res: ICalendarAppointments[]) => {
          const transformedEvents: any[] = res.map((slot: ICalendarAppointments) => {
            const start = new Date(`${slot.start_at}`);
            const end = new Date(`${slot.end_at}`);
            let startTime = format(start, 'HH:mm');
            let endTime = format(end, 'HH:mm');

            return {
              ...slot,
              title: `${startTime}-${endTime}`
            };
          });

          ctx.patchState({
            appointments: [
              ...transformedEvents
            ],
            isLoading: false
          });
      })
    );
  }

}

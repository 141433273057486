export default {
  forms: {
    errors: {
      required: '{{label}} є обов`язковим полем',
      email: 'Введіть коректний email',
      mask: 'Невірно введений номер'
    }
  },
  errors: {
    4031: 'Час зустрічі вже заброньовано',
    4060: 'Ви перевищили ліміт працівників для free_tier. Оновіть підписку, щоб додати більше співробітників.',
    4042: 'Клієнт з таким номером телефону вже доданий'
  }
}

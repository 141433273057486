import { IGetCalendarAppointments, ISetScheduleSlotBody } from "./calendar-state.interface";

export namespace CalendarActions {
  export class FetchEmployeeSchedule {
    static readonly type = '[CalendarState] Fetch Employee Schedule';

    constructor(public params: {
      schedule_id: string;
      range_from: string;
      range_to: string;
    }) {}
  }

  export class SetEmployeeSchedule {
    static readonly type = '[CalendarState] Set/Update Employee Schedule';

    constructor(public params: {
      schedule_id: string;
      data:ISetScheduleSlotBody[];
    }) {}
  }

  export class DeleteEmployeeSchedule {
    static readonly type = '[CalendarState] Delete Employee Schedule';

    constructor(public params: {schedule_id: string, data: string[]}) {}
  }

  export class FetchCalendarAppointments {
    static readonly type = '[CalendarState] Fetch Calendar Appointments';

    constructor(public params: IGetCalendarAppointments) {}
  }

}
